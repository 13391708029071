import React from "react";
import Lottie from "react-lottie";
import animationData from "../assets/img/rotate.json";

function Rotate() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div id="rotate">
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h5 className="small-header text-center" style={{ fontSize: "2rem" }}>
            Please Rotate <br /> Your Phone
          </h5>
          <Lottie options={defaultOptions} className="img-fluid" />
        </div>
      </div>
    </div>
  );
}

export default Rotate;
