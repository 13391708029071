import React, { useState } from "react";
import Minting from "./Minting";
import {
  useAccount,
  useContractWrite,
  useReadContract,
  useReadContracts,
  useWriteContract,
} from "wagmi";
import StakeScreen from "./StakeScreen";
import Inventory from "./Inventory";
import {
  enBuyukSayiBul,
  getMetaDataFromNumber,
  getTokenBalance,
  removeDecimal,
} from "../helpers/functions";
import { useEffect } from "react";
import { planetAbi } from "../abi/planetAbi";
import { stakingAbi } from "../abi/stakingAbi";
import { info } from "../info";
import planetMetadata from "../assets/metadata/newplanets.json";
import starMetadata from "../assets/metadata/newstars.json";
import Lottie from "react-lottie";
import animationData2 from "../assets/img/loading.json";
import { starAbi } from "../abi/starAbi";
import ConnectComponent from "../components/ConnectComponent";
import Rotate from "../components/Rotate";
import { useMediaQuery } from "@uidotdev/usehooks";
import { config } from "../App";

function NewIndex() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 900px)");
  const { address, isConnected } = useAccount();
  const [showinventory, setshowinventory] = useState(false);
  const [showStake, setShowStake] = useState(false);
  const [showMinting, setShowMinting] = useState(false);
  const [slotCount, setslotCount] = useState(null);
  const [stakeArray, setStakeArray] = useState(null);
  const [stakeReward, setStakeReward] = useState(null);
  const [balance, setBalance] = useState(null);
  const [chosenSlot, setChosenSlot] = useState(null);
  const [starMetaData, setstarMetaData] = useState(null);
  const [starLoading, setstarLoading] = useState(true);
  const getOrientation = () => window.screen.orientation.type;
  const [orientation, setorientation] = useState(getOrientation());
  const [starMultiCallArray, setstarMultiCallArray] = useState(null);
  const [planetMultiCallArray, setplanetMultiCallArray] = useState(null);
  const [powerLevel, setpowerLevel] = useState(null);
  const [multiplier, setmultiplier] = useState(null);
  const { writeContract } = useWriteContract();

  useEffect(() => {
    window.screen.orientation.addEventListener("change", function (e) {
      setorientation(getOrientation());
    });
  }, []);

  useEffect(() => {
    if (
      orientation == "portrait-primary" ||
      orientation == "portrait-secondary" ||
      orientation == "portrait"
    ) {
      setShowMinting(false);
      setshowinventory(false);
    }
  }, [orientation]);

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const inventoryToMint = () => {
    setshowinventory(false);
    setShowMinting(true);
  };

  const mintToInventory = () => {
    setShowMinting(false);
    setshowinventory(true);
  };

  /* Inventory işlemleri */

  const starcontract = {
    address: info.starContractAddress,
    abi: starAbi,
  };

  const planetcontract = {
    address: info.planetContractAddress,
    abi: planetAbi,
  };

  const { data: starresult } = useReadContracts({
    contracts: starMetadata.map((e) => ({
      ...starcontract,
      functionName: "balanceOf",
      args: [address, e.id],
    })),
    watch: true,
  });

  const { data: planetresult } = useReadContracts({
    contracts: planetMetadata.map((e) => ({
      ...planetcontract,
      functionName: "balanceOf",
      args: [address, e.id],
    })),
    watch: true,
  });

  useEffect(() => {
    console.log(starresult, planetresult);
    if (starresult && starresult.length > 0) {
      const starArr = starresult.map((e) => e.result && e.result.toString());
      const num = enBuyukSayiBul(starArr);
      console.log(num);
      if (num) {
        const starmeta = getMetaDataFromNumber(num, starMetadata);
        setslotCount(Number(starmeta.slots));
        setstarMetaData(starmeta);
        console.log(num);
      }
      setstarLoading(false);
    }
  }, [starresult]);

  const { data: stakeData } = useReadContract({
    address: info.stakingAddress,
    abi: stakingAbi,
    functionName: "getStakeInfo",
    args: [address],
    watch: true,
  });

  useEffect(() => {
    if (address && stakeData) {
      console.log(stakeData);
      const reward = stakeData[2];
      let nftArr = [];
      for (let i = 0; i < stakeData[1].length; i++) {
        for (let k = 0; k < Number(stakeData[1][i].toString()); k++) {
          nftArr.push(Number(stakeData[0][i].toString()));
        }
      }
      console.log(nftArr);
      setStakeArray(nftArr);
      var totalPower = 0;

      for (let i = 0; i < nftArr.length; i++) {
        totalPower += Number(
          planetMetadata.filter((e) => e.id == nftArr[i])[0].power
        );
      }
      setpowerLevel(totalPower);
      setmultiplier((totalPower / 10000).toFixed(2));
      setStakeReward(reward.toString());
    }
  }, [address, stakeData]);

  useEffect(() => {
    console.log(isSmallDevice);
  }, [isSmallDevice]);

  /* Stake işlemleri  */

  useEffect(() => {
    const getBalance = async () => {
      if (address) {
        const tokenBal = await getTokenBalance(
          address,
          info.cosmotesContractAddress
        );
        setBalance(removeDecimal(tokenBal, 18, 0));
      }
    };
    getBalance();
  }, [address]);
  return (
    <>
      {!address && <ConnectComponent />}
      {address && isConnected && (
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Rotate />
          <div
            className="container-fluid"
            style={{ marginX: "auto" }}
            id="screen"
          >
            <div className="row">
              <div className="col-8">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    minHeight: "70vh",
                  }}
                >
                  {starLoading && (
                    <div>
                      <h5
                        className="small-header text-center"
                        style={{ fontSize: "2rem" }}
                      >
                        Loading
                      </h5>
                      <Lottie
                        options={defaultOptions2}
                        width="200px"
                        height="200px"
                        className="img-fluid"
                      />
                    </div>
                  )}
                  <>
                    {slotCount > 0 && !starLoading && (
                      <>
                        <div
                          style={{
                            borderRadius: "50%",
                            width: "45%",
                            height: "50vh",
                            border: "2px solid #7074CB",
                            transform: "rotate(-12.00deg)",
                            position: "absolute",
                          }}
                        >
                          {slotCount >= 6 && stakeArray && (
                            <>
                              {Number(stakeArray[5]) >= 0 ? (
                                <img
                                  style={{
                                    position: "absolute",
                                    bottom: "calc(30% - 0px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    left: isSmallDevice ? "-35px" : "-40px", // Gezegeni yörüngenin merkezine hizalamak için
                                    width: isSmallDevice ? "70px" : "100px",
                                  }}
                                  src={
                                    getMetaDataFromNumber(
                                      stakeArray[5],
                                      planetMetadata
                                    ).image
                                  }
                                  className="img-fluid"
                                  onClick={() => setChosenSlot(stakeArray[5])}
                                />
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    width: isSmallDevice ? "15px" : "20px",
                                    height: isSmallDevice ? "15px" : "20px",
                                    border: "3px solid #878DF6",
                                    backgroundColor: "#0C143D",
                                    position: "absolute",
                                    bottom: "calc(40% - 10px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    left: "-2px", // Gezegeni yörüngenin merkezine hizalamak için
                                  }}
                                  onClick={() => setChosenSlot("empty")}
                                ></div>
                              )}
                            </>
                          )}
                          {slotCount >= 5 && stakeArray && (
                            <>
                              {Number(stakeArray[4]) >= 0 ? (
                                <img
                                  style={{
                                    position: "absolute",
                                    top: "calc(40% - 10px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    right: isSmallDevice ? "-35px" : "-50px", // Gezegeni yörüngenin merkezine hizalamak için
                                    width: isSmallDevice ? "70px" : "100px",
                                  }}
                                  src={
                                    getMetaDataFromNumber(
                                      stakeArray[4],
                                      planetMetadata
                                    ).image
                                  }
                                  className="img-fluid"
                                  onClick={() => setChosenSlot(stakeArray[4])}
                                />
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    width: isSmallDevice ? "15px" : "20px",
                                    height: isSmallDevice ? "15px" : "20px",
                                    border: "3px solid #878DF6",
                                    backgroundColor: "#0C143D",
                                    position: "absolute",
                                    top: "calc(40% - 10px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    right: "-5px", // Gezegeni yörüngenin merkezine hizalamak için
                                  }}
                                  onClick={() => setChosenSlot("empty")}
                                ></div>
                              )}
                            </>
                          )}
                        </div>
                        <div
                          style={{
                            borderRadius: "50%",
                            width: "30%",
                            height: "35vh",
                            border: "2px solid #7074CB",
                            transform: "rotate(-12.00deg)",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                          }}
                        >
                          {slotCount >= 4 && stakeArray && (
                            <>
                              {Number(stakeArray[3]) >= 0 ? (
                                <img
                                  style={{
                                    position: "absolute",
                                    top: "calc(20% - 10px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    left: isSmallDevice ? "-30px" : "-40px", // Gezegeni yörüngenin merkezine hizalamak için
                                    width: isSmallDevice ? "70px" : "100px",
                                  }}
                                  src={
                                    getMetaDataFromNumber(
                                      stakeArray[3],
                                      planetMetadata
                                    ).image
                                  }
                                  onClick={() => setChosenSlot(stakeArray[3])}
                                  className="img-fluid"
                                />
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    width: isSmallDevice ? "15px" : "20px",
                                    height: isSmallDevice ? "15px" : "20px",
                                    border: "3px solid #878DF6",
                                    position: "absolute",
                                    top: "calc(30% - 10px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    left: isSmallDevice ? "5px" : "8px", // Gezegeni yörüngenin merkezine hizalamak için
                                    backgroundColor: "#0C143D",
                                  }}
                                  onClick={() => setChosenSlot("empty")}
                                ></div>
                              )}
                            </>
                          )}

                          {slotCount >= 3 && stakeArray && (
                            <>
                              {Number(stakeArray[2]) >= 0 ? (
                                <img
                                  style={{
                                    position: "absolute",
                                    bottom: "calc(10% - 10px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    right: isSmallDevice ? "-20px" : "-10px", // Gezegeni yörüngenin merkezine hizalamak için
                                    width: isSmallDevice ? "70px" : "100px",
                                  }}
                                  src={
                                    getMetaDataFromNumber(
                                      stakeArray[2],
                                      planetMetadata
                                    ).image
                                  }
                                  onClick={() => setChosenSlot(stakeArray[2])}
                                  className="img-fluid"
                                />
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    width: isSmallDevice ? "15px" : "20px",
                                    height: isSmallDevice ? "15px" : "20px",
                                    border: "3px solid #878DF6",
                                    position: "absolute",
                                    bottom: "calc(20% - 10px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    right: isSmallDevice ? "20px" : "40px", // Gezegeni yörüngenin merkezine hizalamak için
                                    backgroundColor: "#0C143D",
                                  }}
                                  onClick={() => setChosenSlot("empty")}
                                ></div>
                              )}
                            </>
                          )}
                        </div>
                        <div
                          style={{
                            borderRadius: "50%",
                            width: "20%",
                            height: "20vh",
                            border: "2px solid #7074CB",
                            transform: "rotate(-12.00deg)",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                          }}
                        >
                          {slotCount >= 2 && stakeArray && (
                            <>
                              {Number(stakeArray[1]) >= 0 ? (
                                <img
                                  style={{
                                    position: "absolute",
                                    bottom: "calc(10% - 20px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    left: isSmallDevice ? "0px" : "0px", // Gezegeni yörüngenin merkezine hizalamak için
                                    width: isSmallDevice ? "50px" : "70px",
                                  }}
                                  src={
                                    getMetaDataFromNumber(
                                      stakeArray[1],
                                      planetMetadata
                                    ).image
                                  }
                                  onClick={() => setChosenSlot(stakeArray[1])}
                                  className="img-fluid"
                                />
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    width: isSmallDevice ? "15px" : "20px",
                                    height: isSmallDevice ? "15px" : "20px",
                                    border: "3px solid #878DF6",
                                    position: "absolute",
                                    bottom: "calc(24% - 20px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    left: "25px", // Gezegeni yörüngenin merkezine hizalamak için
                                    backgroundColor: "#0C143D",
                                  }}
                                  onClick={() => setChosenSlot("empty")}
                                ></div>
                              )}
                            </>
                          )}

                          <img
                            src={starMetaData && starMetaData.image}
                            style={{
                              position: "absolute",
                              left: "35%",
                              top: isSmallDevice ? "0" : "15%",
                              width: isSmallDevice ? "70px" : "100px",
                            }}
                            alt=""
                          />

                          {slotCount >= 1 && stakeArray && (
                            <>
                              {Number(stakeArray[0]) >= 0 ? (
                                <img
                                  style={{
                                    position: "absolute",
                                    top: "calc(10% - 0px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    right: isSmallDevice ? "-10px" : "-10px", // Gezegeni yörüngenin merkezine hizalamak için
                                    width: isSmallDevice ? "40px" : "70px",
                                  }}
                                  src={
                                    getMetaDataFromNumber(
                                      stakeArray[0],
                                      planetMetadata
                                    ).image
                                  }
                                  onClick={() => setChosenSlot(stakeArray[0])}
                                  className="img-fluid"
                                />
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    width: isSmallDevice ? "15px" : "20px",
                                    height: isSmallDevice ? "15px" : "20px",
                                    border: "3px solid #878DF6",
                                    position: "absolute",
                                    top: "calc(40% - 10px)", // Gezegeni yörüngenin merkezine hizalamak için
                                    right: "-5px", // Gezegeni yörüngenin merkezine hizalamak için
                                    backgroundColor: "#0C143D",
                                  }}
                                  onClick={() => setChosenSlot("empty")}
                                ></div>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {!starLoading && !slotCount && (
                      <div>
                        <h5 className="small-header">
                          Click To Mint <br /> Your Free Star
                        </h5>
                        <div
                          className="button"
                          onClick={() =>
                            writeContract({
                              address: info.starContractAddress,
                              abi: starAbi,
                              functionName: "getFreeStar",
                            })
                          }
                        >
                          Mint
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
              <div className="col-4">
                <div
                  style={{
                    display: "flex",
                    width: "80%",
                    height: "80%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="planet-data"
                >
                  <>
                    <div className="row">
                      <div className="col-3">
                        <img
                          src={"/assets/img/linecirlce.png"}
                          alt=""
                          className="linedots"
                        />
                      </div>
                      {(typeof chosenSlot == "number" || chosenSlot == 0) && (
                        <div className="col-9">
                          <div style={{ float: "right" }}>
                            <div>
                              <h1
                                className="responsive-header"
                                style={{ fontWeight: "bold" }}
                              >
                                {
                                  getMetaDataFromNumber(
                                    chosenSlot,
                                    planetMetadata
                                  ).name
                                }
                              </h1>
                              <h5 className="responsive-text">
                                {
                                  getMetaDataFromNumber(
                                    chosenSlot,
                                    planetMetadata
                                  ).rarity
                                }
                              </h5>
                            </div>
                            <div className="responsive-margin-top">
                              <h5 className="responsive-text">
                                Power |{" "}
                                {
                                  getMetaDataFromNumber(
                                    chosenSlot,
                                    planetMetadata
                                  ).power
                                }
                              </h5>
                              <img
                                src={`/assets/bars/power${
                                  (Number(
                                    getMetaDataFromNumber(
                                      chosenSlot,
                                      planetMetadata
                                    ).id
                                  ) %
                                    5) +
                                  1
                                }.png`}
                                alt=""
                                style={{ width: "80%" }}
                              />
                            </div>
                            <div className="responsive-margin-top">
                              <h5 className="responsive-text">
                                Rank |{" "}
                                {
                                  getMetaDataFromNumber(
                                    chosenSlot,
                                    planetMetadata
                                  ).rank
                                }
                              </h5>
                              <img
                                src={`/assets/bars/power${
                                  getMetaDataFromNumber(
                                    chosenSlot,
                                    planetMetadata
                                  ).rank
                                }.png`}
                                alt=""
                                style={{ width: "80%" }}
                              />
                            </div>
                            <div className="responsive-margin-top">
                              <h5 className="responsive-text">
                                Bonus |{" "}
                                {
                                  getMetaDataFromNumber(
                                    chosenSlot,
                                    planetMetadata
                                  ).boost
                                }
                              </h5>
                            </div>

                            <div className="responsive-margin-top">
                              <div
                                className="new-buttons"
                                onClick={() => {
                                  writeContract({
                                    args: [chosenSlot, "1"],
                                    address: info.stakingAddress,
                                    abi: stakingAbi,
                                    functionName: "withdraw",
                                  });
                                }}
                              >
                                UNSTAKE
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "10px",
                      border: "1px solid white",
                      padding: "5px",
                      width: "80%",
                      minHeight: "45px",
                      padding: "12px",
                    }}
                  >
                    <div className="row">
                      <div
                        className="col-8"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          className="new-buttons"
                          onClick={() =>
                            writeContract({
                              address: info.stakingAddress,
                              abi: stakingAbi,
                              functionName: "claimAll",
                              args: [address],
                            })
                          }
                        >
                          CLAIM{" "}
                          {stakeReward
                            ? Math.round(
                                Number(removeDecimal(stakeReward, 18, 4))
                              )
                            : "0"}{" "}
                          COSMOTES
                        </div>
                        <h5
                          style={{ marginLeft: "10px" }}
                          className="responsive-text"
                        >
                          <span style={{ marginRight: "10px" }}>
                            <img
                              src="/assets/img/powerplaceholder.svg"
                              alt=""
                              style={{ marginLeft: "10px", width: "25px" }}
                            />
                          </span>
                          {balance && balance}
                        </h5>

                        <h5
                          style={{ marginLeft: "10px" }}
                          className="responsive-text"
                        >
                          <span style={{ marginRight: "10px" }}>
                            <img
                              src="/assets/img/cosmotesplaceholder.svg"
                              alt=""
                              style={{
                                marginLeft: "10px",
                                width: "25px",
                              }}
                            />
                          </span>
                          {powerLevel && powerLevel}
                        </h5>
                      </div>
                      <div
                        className="col-4"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div
                          className="new-inventory-buttons"
                          style={{
                            marginRight: "10px",
                            paddingRight: "1rem",
                            paddingLeft: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => setshowinventory(true)}
                        >
                          <img
                            src="/assets/img/planeticon.svg"
                            alt=""
                            style={{ width: "35px" }}
                          />
                        </div>
                        <div
                          className="new-inventory-buttons"
                          style={{
                            marginRight: "10px",
                            paddingRight: "1rem",
                            paddingLeft: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => setShowMinting(true)}
                        >
                          <img
                            src="/assets/img/forgeicon.svg"
                            style={{ width: "35px" }}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showinventory && (
        <Inventory
          show={showinventory}
          setShow={setshowinventory}
          planetresult={planetresult}
          starresult={starresult}
          inventoryToMint={inventoryToMint}
          planetMetadata={planetMetadata}
          starMetadata={starMetadata}
        />
      )}
      <StakeScreen show={showStake} setShow={setShowStake} />
      <Minting
        show={showMinting}
        setShow={setShowMinting}
        mintToInventory={mintToInventory}
      />
    </>
  );
}

export default NewIndex;
