import React from "react";
import { ConnectKitButton } from "connectkit";
import { truncateEthereumAddress } from "../helpers/functions";

function ConnectComponent() {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ConnectKitButton.Custom showBalance={true}>
          {({
            isConnected,
            isConnecting,
            show,
            hide,
            address,
            ensName,
            chain,
          }) => {
            return (
              <div
                onClick={show}
                style={{
                  width: 292,
                  height: 79,
                  background: "#4854C7",
                  borderRadius: 10,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  fontFamily: "Poppins",
                }}
              >
                <h5>
                  {isConnected
                    ? truncateEthereumAddress(address)
                    : "Connect Wallet"}
                </h5>
              </div>
            );
          }}
        </ConnectKitButton.Custom>
      </div>
    </>
  );
}

export default ConnectComponent;
