import React, { createContext, useContext, useEffect, useState } from "react";
import { info } from "../info";
import { getPlanetMintFee, removeDecimal } from "./functions";
import { planetAbi } from "../abi/planetAbi";

const GlobalStateContext = createContext();

// 2. Global state'i sağlayacak bir provider oluşturun
export const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    starMintFee: "",
    starFeeDecimals: "",
  });

  useEffect(() => {
    const getContractValues = async () => {
      try {
        const planetFeeDecimal = await getPlanetMintFee(
          info.planetContractAddress
        );
        const planetFee = removeDecimal(planetFeeDecimal.toString(), 18, 2);

        const starFeeDecimal = await getPlanetMintFee(info.starContractAddress);
        const starFee = removeDecimal(starFeeDecimal.toString(), 18, 2);

        var contracts = [];
        var attr = {
          address: info.planetContractAddress,
          abi: planetAbi,
          functionName: "getAttribute",
        };
        for (let i = 0; i < info.planetsLength + 1; i++) {
          const r = { ...attr, args: [String(i)] };
          contracts.push(r);
        }

        setGlobalState({
          ...globalState,
          planetFeeDecimal: planetFeeDecimal.toString(),
          planetFee: planetFee,
          starFee: starFee,
          starFeeDecimal: starFeeDecimal,
        });
      } catch (error) {
        console.log(error);
      }
    };

    getContractValues();
  }, []);

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// 3. useContext kullanarak global state'e erişmek için bir özel bir hook oluşturun
export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("Global state is on wrong place");
  }
  return context;
};
