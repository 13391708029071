import { readContract, fetchBalance } from "@wagmi/core";
import { tokenAbi } from "../abi/tokenAbi";
import BigNumber from "bignumber.js";
import { planetAbi } from "../abi/planetAbi";
import { info } from "../info";
import { config } from "../App";

// Token balance'ına ondalık eklemek için
export function removeDecimal(balance, decimal, fixed) {
  if (decimal > 0) {
    const balanceBig = new BigNumber(String(balance));
    const decimalBig = new BigNumber(String(10)).exponentiatedBy(
      String(decimal)
    );
    const result = new BigNumber(balanceBig.dividedBy(decimalBig));
    return result.toFixed(fixed);
  } else {
    const balanceBig = new BigNumber(String(balance));
    return balanceBig.toFixed(fixed);
  }
}

// Token balance'ından ondalık çıkarmak için
export function addDecimal(balance, decimal, fixed) {
  if (decimal > 0) {
    const balanceBig = new BigNumber(String(balance));
    const decimalBig = new BigNumber(String(10)).exponentiatedBy(
      String(decimal)
    );
    const result = new BigNumber(balanceBig.multipliedBy(decimalBig));
    return result.toFixed(fixed);
  } else {
    const balanceBig = new BigNumber(String(balance));
    return balanceBig.toFixed(fixed);
  }
}

export const getTokenBalance = async (walletAddress, tokenAddress) => {
  try {
    const data = await readContract(config, {
      address: tokenAddress,
      abi: tokenAbi,
      functionName: "balanceOf",
      args: [walletAddress],
    });
    return data.toString();
  } catch (error) {
    return null;
  }
};

export const getErc1155Balance = async (walletAddress, tokenAddress) => {
  try {
    const data = await readContract(config, {
      address: tokenAddress,
      abi: planetAbi,
      functionName: "balanceOf",
      args: [walletAddress],
    });
    return data.toString();
  } catch (error) {
    return null;
  }
};

export const getPlanetMintFee = async (erc1155Address) => {
  try {
    const data = await readContract(config, {
      address: erc1155Address,
      abi: planetAbi,
      functionName: "mintFee",
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const getPlanetAttributes = async (id) => {
  try {
    const data = await readContract(config, {
      address: info.planetContractAddress,
      abi: planetAbi,
      functionName: "getAttribute",
      args: [id],
    });
    return {
      id: id,
      rarity: Number(data[0].toString()) / 10,
      power: data[1].toString(),
      rank: data[2].toString(),
      race: data[3].toString() == "1" ? "Human" : "Orc",
    };
  } catch (error) {
    return null;
  }
};

export function truncateEthereumAddress(address) {
  const truncatedAddress =
    address.substring(0, 6) + "..." + address.substring(address.length - 4);
  return truncatedAddress;
}

export const filterMinted = (mintedId, nftArray) => {
  const arr = nftArray;
  console.log(arr.filter((e) => e.id == Number(mintedId)));
  return arr.filter((e) => e.id == Number(mintedId))[0];
};

export function enBuyukSayiBul(array) {
  if (array.length === 0) {
    return null;
  }

  const ifthereis = array.filter((e) => e && Number(e.toString()) > 0);
  if (!ifthereis) {
    return null;
  }

  let enBuyuk = 350; // İlk elemanı en büyük kabul edelim

  for (let i = 0; i < array.length - 1; i++) {
    if (Number(array[i]) > 0) {
      if (i != 8 && i != 10) {
        enBuyuk = i;
      }
    }
  }

  if (enBuyuk > array.length - 1) {
    if (Number(array[10]) > 0) {
      return 10;
    } else {
      return null;
    }
  }

  return enBuyuk;
}

export const getMetaDataFromNumber = (number, metaArray) => {
  return metaArray.filter((e) => e.id == number)[0];
};
