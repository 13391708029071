import { useToggle } from "@uidotdev/usehooks";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import { planetAbi } from "../abi/planetAbi";
import { stakingAbi } from "../abi/stakingAbi";
import { starAbi } from "../abi/starAbi";
import { getMetaDataFromNumber } from "../helpers/functions";
import { info } from "../info";

function Inventory({
  show,
  setShow,
  planetresult,
  starresult,
  inventoryToMint,
  planetMetadata,
  starMetadata,
}) {
  const { writeContract } = useWriteContract();

  const { address, isConnected } = useAccount();

  const handleClose = () => setShow(false);

  const [on, toggle] = useToggle(true);

  const planetApproved = useReadContract({
    address: info.planetContractAddress,
    abi: planetAbi,
    functionName: "isApprovedForAll",
    watch: true,
    args: [address, info.stakingAddress],
    watch: true,
  });

  const starApproved = useReadContract({
    address: info.starContractAddress,
    abi: starAbi,
    functionName: "isApprovedForAll",
    watch: true,
    args: [address, info.stakingAddress],
    watch: true,
  });

  const StarCard = ({ id, count }) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseOver = (e) => {
      setIsHovered(true);
    };
    const [metadata, setmetadata] = useState(null);

    useEffect(() => {
      if (on) {
        const meta = getMetaDataFromNumber(id, planetMetadata);
        setmetadata(meta);
      } else {
        {
          const meta = getMetaDataFromNumber(id, starMetadata);
          setmetadata(meta);
        }
      }
    }, [on]);

    return (
      <div
        className="col-4 mt-2"
        onMouseOver={handleMouseOver}
        onMouseOut={() => setIsHovered(false)}
      >
        {isHovered ? (
          <div
            style={{
              width: "95%",
              borderRadius: "8px",
              backgroundColor: "rgba(72, 84, 199, 0.1)",
              minHeight: "160px",
            }}
            className="p-2"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {Object.keys(metadata).map((key, index) => {
                if (key != "id" && key != "image")
                  return (
                    <h5 className="inventory-header" key={index}>
                      {key.toUpperCase()} : {metadata[key].toUpperCase()}
                    </h5>
                  );
              })}
              {!on && count >= 2 && (
                <>
                  {starApproved ? (
                    <div
                      className="button-inventory"
                      onClick={() => {
                        writeContract({
                          address: info.starContractAddress,
                          abi: starAbi,
                          functionName: "merge",

                          args: [id],
                        });
                      }}
                    >
                      Merge
                    </div>
                  ) : (
                    <div
                      className="button-inventory"
                      onClick={() =>
                        writeContract({
                          address: info.starContractAddress,
                          abi: starAbi,
                          functionName: "setApprovalForAll",
                          args: [info.stakingAddress, true],
                        })
                      }
                    >
                      Approve
                    </div>
                  )}
                </>
              )}
              {on && (
                <>
                  {planetApproved ? (
                    <div
                      className="button-inventory"
                      onClick={() => {
                        writeContract({
                          address: info.stakingAddress,
                          abi: stakingAbi,
                          functionName: "stake",

                          args: [id, "1"],
                        });
                      }}
                    >
                      Stake
                    </div>
                  ) : (
                    <div
                      className="button-inventory"
                      onClick={() =>
                        writeContract({
                          address: info.planetContractAddress,
                          abi: planetAbi,
                          functionName: "setApprovalForAll",
                          args: [info.stakingAddress, true],
                        })
                      }
                    >
                      Approve
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "0px",
                  left: "5px",
                  color: "white",
                  backgroundColor: "#4854c7",
                  borderRadius: "50%",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {count}
              </div>
              <img
                src={metadata && metadata.image}
                width="100%"
                className="img-fluid"
              />
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      {address && isConnected && (
        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="custom-modal"
          centered={true}
          data-bs-theme="dark"
          fullscreen="lg-down"
          size="xl"
          scrollable="false"
        >
          <div
            className="row"
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="row pb-3">
              <h1 className="text-center mb-3">
                INVENTORY
                <span
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={handleClose}
                >
                  <img src="/assets/img/closebutton.svg" alt="" />
                </span>
              </h1>

              <div className="col-2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid white",
                      borderRadius: "5px",
                      width: "60%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "60px",
                      backgroundColor: !on && "#3f489b",
                      cursor: "pointer",
                    }}
                    onClick={() => toggle(false)}
                  >
                    <img src="/assets/img/star.svg" alt="" />
                  </div>
                  <div
                    style={{
                      border: "1px solid white",
                      borderRadius: "5px",
                      width: "60%",
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "60px",
                      backgroundColor: on && "#3f489b",
                      cursor: "pointer",
                    }}
                    onClick={() => toggle(true)}
                  >
                    {" "}
                    <img src="/assets/img/planet.svg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-10">
                <div className="row inventory custom-textarea text-start">
                  {on &&
                    planetresult &&
                    planetresult.map(
                      (e, i) =>
                        Number(e.result.toString()) > 0 && (
                          <StarCard
                            id={i}
                            count={e.result.toString()}
                            key={i}
                          />
                        )
                    )}
                  {!on &&
                    starresult &&
                    starresult.map(
                      (e, i) =>
                        Number(e.result.toString()) > 0 && (
                          <StarCard
                            id={i}
                            count={e.result.toString()}
                            key={i}
                          />
                        )
                    )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Inventory;
